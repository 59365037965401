.main-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

.star-click {
    color: #c8d175 !important;
}

.star-click:hover {
    color: #b0b869 !important;
    transform: scale(1.05);
}
  